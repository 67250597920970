<template>
  <ParagraphBaseImageVideoBase
    :overline="overline"
    :headline="headline"
    :link="textLink?.uri?.path"
    :is-editing="isEditing"
    :overlay="options.overlay"
    class="paragraph"
  >
    <template #image>
      <MediaImage
        v-blokkli-droppable:field_media_image
        v-bind="image"
        class="h-full w-full"
        img-class="w-full h-full object-cover"
        :image-style="imageStyle"
      />
    </template>

    <template v-if="textLink?.uri?.path" #callToActionText>
      {{ $texts('readMore', 'Read more') }}
    </template>
  </ParagraphBaseImageVideoBase>
</template>

<script lang="ts" setup>
import type { ParagraphImageFragment } from '#graphql-operations'

const { options, isEditing } = defineBlokkli({
  bundle: 'image',
  options: {
    overlay: {
      type: 'checkbox',
      label: 'Overlay',
      default: true,
    },
  },
})

defineProps<ParagraphImageFragment>()

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 420,
      aspectRatio: 9 / 16,
    },
    md: {
      width: 800,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: 1200,
      aspectRatio: 16 / 9,
    },
    xl: {
      width: 1440,
      aspectRatio: 16 / 9,
    },
    xxl: {
      width: 2000,
      aspectRatio: 16 / 9,
    },
  },
})
</script>
