<template>
  <div
    v-if="images?.length"
    class="gallery-grid-container paragraph flex flex-wrap gap-[15px] md:gap-24"
    :class="{
      'right-aligned': rightAligned,
      'left-aligned': !rightAligned,
    }"
  >
    <MediaImage
      v-for="(image, index) in images"
      :key="index"
      v-bind="image"
      style-name="fe_paragraph_image_original"
      class="gallery-item-normal order-3 inline-block"
      :class="{
        'gallery-item-full':
          index === 0 ||
          images.length === 2 ||
          images.length === 4 ||
          index === 5,
        'md:gallery-item-small': index === 1 || index === 2,
        'md:gallery-item-big': index === 0 || index === 3,
        'md:gallery-item-normal':
          index === 5 ||
          (hasText &&
            ((index === 2 && images.length === 4) ||
              (index >= 3 && images.length >= 4))),
        'md:gallery-item-big-right':
          !hasText && index === 2 && images?.length === 3,
        'md:gallery-item-big-left':
          !hasText && index === 4 && images?.length === 5,
        '!order-1':
          index === 0 ||
          (index === 1 && images.length !== 2) ||
          (index === 2 && images?.length % 2 === 1),
        'md:order-1': index < 2,
      }"
    />
    <Text
      v-if="hasText"
      :class="{
        '!w-full': images?.length === 2 || images?.length === 4,
      }"
      class="gallery-grid-text-item md:gallery-item-big order-2 flex w-full flex-col justify-center py-24 text-base md:py-0"
    >
      <!-- we use percentage fontsizes here so the scaling from the Text element works proportionally -->
      <!-- text-4xl / text-base = 250%; line-height of text-4xl = 1.2 -->
      <!-- mobile text = 32 / 16 = 200%; line-height: 40 / 32 = 1.25 -->
      <div
        v-blokkli-editable:field_headline
        class="text-[200%] font-light uppercase [line-height:125%] md:text-[250%] md:[line-height:120%]"
      >
        {{ headline }}
      </div>
      <!-- text-2xl / text-base = 150%; line-height of text-2xl = 1.33 -->
      <!-- mobile text = 20 / 16 = 125%; line-height: 32 / 20 = 1.6 -->
      <div
        v-blokkli-editable:field_sub_headline
        class="my-16 text-[125%] font-light uppercase [line-height:160%] md:text-[150%] md:[line-height:133%]"
      >
        {{ subHeadline }}
      </div>
      <div
        v-blokkli-editable:field_description="{ type: 'frame' }"
        class="rich-text h-min font-light [line-height:150%]"
        v-html="description"
      ></div>
    </Text>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphGalleryGridFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'gallery_grid',
  options: {
    alignment: {
      type: 'radios',
      label: 'Alignment',
      default: 'left',
      options: {
        left: 'Left',
        right: 'Right',
      },
    },
  },
  editor: {
    determineVisibleOptions: (ctx) => {
      // The alignment option only affects the layout if there is an uneven
      // amount of images.
      const totalImages = ctx.props.images?.length || 0
      if (!totalImages) {
        return []
      }
      const hasImagesUneven = totalImages % 2 !== 0
      if (hasImagesUneven) {
        return ['alignment']
      }

      return []
    },
  },
})

const props = defineProps<{
  headline?: ParagraphGalleryGridFragment['headline']
  subHeadline?: ParagraphGalleryGridFragment['subHeadline']
  description?: ParagraphGalleryGridFragment['description']
  images?: ParagraphGalleryGridFragment['images']
}>()

const rightAligned = computed(() => options.value.alignment === 'right')
const hasText = computed(
  () => props.headline || props.subHeadline || props.description,
)
</script>

<style lang="postcss" scoped>
:deep(img) {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.gallery-item-big {
  width: calc((100cqw - 15px) / 25 * 16);
  height: calc((100cqw - 15px) / 25 * 9);
}

.gallery-item-small {
  width: calc((100cqw - 15px) / 25 * 9);
  height: calc((100cqw - 15px) / 25 * 9);
}

.gallery-item-normal {
  width: calc((100cqw - 15px) / 2);
  height: calc((100cqw - 15px) / 25 * 9);
}

.gallery-item-full {
  width: 100cqw;
  aspect-ratio: 16 / 9;
}

@media (screen(md)) {
  .gallery-item-big {
    width: calc((100cqw - 24px) / 25 * 16);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .gallery-item-normal {
    width: calc((100cqw - 24px) / 2);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .gallery-item-small {
    width: calc((100cqw - 24px) / 25 * 9);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .md\:gallery-item-big {
    width: calc((100cqw - 24px) / 25 * 16);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .md\:gallery-item-small {
    width: calc((100cqw - 24px) / 25 * 9);
    height: calc((100cqw - 24px) / 25 * 9);
  }

  .md\:gallery-item-normal {
    width: calc((100cqw - 24px) / 2);
    height: calc((100cqw - 24px) / 25 * 9);
  }
}

.gallery-grid-container {
  container-type: inline-size;

  &.left-aligned {
    flex-direction: row-reverse;
  }

  @media (screen(md)) {
    &.right-aligned .md\:gallery-item-big-left,
    &.left-aligned .md\:gallery-item-big-right {
      @apply gallery-item-big;
      margin-right: calc((100cqw - 24px) / 25 * 9 + 24px);
    }

    &.right-aligned .md\:gallery-item-big-right,
    &.left-aligned .md\:gallery-item-big-left {
      @apply gallery-item-big;
      margin-left: calc((100cqw - 24px) / 25 * 9 + 24px);
    }
  }
}

.gallery-grid-text-item {
  padding-left: min(5cqw, 80px);
  padding-right: min(5cqw, 80px);
}
</style>
