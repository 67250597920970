<template>
  <div
    v-if="currentSlide"
    class="layout-grid mx-auto max-w-screen-xxxl overflow-hidden md:grid-rows-[auto_1fr]"
  >
    <div class="col-span-4 col-start-2 md:col-span-7 md:row-start-1 lg:pl-40">
      <div
        v-if="overline"
        class="typo-overline-large mb-28 uppercase text-grey-dark-01"
      >
        {{ overline }}
      </div>
      <div>
        <button
          v-for="(slide, index) in slides"
          :key="'title_' + index"
          class="typo-headline-1 !pointer-events-auto block w-full text-left uppercase"
          :class="[
            activeIndex === index ? 'text-black' : 'text-grey-mid',
            { 'hover:text-grey-dark-03': activeIndex !== index },
          ]"
          @click.capture.stop="activeIndex = index"
        >
          <Text>
            {{ slide.props.title }}
          </Text>
        </button>
      </div>
    </div>
    <div
      class="relative col-span-3 col-start-3 grid grid-cols-1 grid-rows-1 pt-36 md:col-span-7 md:col-start-7 lg:col-span-4 lg:col-start-8"
    >
      <div
        v-for="(slide, index) in slides"
        :key="'content_' + slide.uuid"
        class="col-start-1 row-start-1 transition-all duration-300 ease-swing"
        :style="getContentStyle(index)"
        :class="{
          'pointer-events-none': activeIndex !== index,
        }"
      >
        <Text>
          <div class="rich-text" v-html="slide.props.text" />
        </Text>
        <div v-if="slide.props.link?.uri?.path" class="mt-36">
          <DrupalLink :to="slide.props.link.uri.path" class="link-button">{{
            $texts('readMore', 'Read more')
          }}</DrupalLink>
        </div>
      </div>
    </div>
    <div
      class="md:col relative col-span-6 col-start-1 mt-32 aspect-square md:row-span-2 md:row-start-1 md:mt-0"
    >
      <Transition
        enter-active-class="duration-300 ease-out absolute top-0 left-0 w-full h-full"
        enter-from-class="transform opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0"
      >
        <MediaImage
          v-bind="currentSlide.props.image"
          :key="'image_' + activeIndex"
          class="absolute left-0 top-0 h-full w-full bg-grey-light-01"
          img-class="h-full w-full"
          :image-style="imageStyle"
        />
      </Transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { FieldListItemTyped } from '#blokkli/generated-types'
import type { ParagraphSwitcherItemFragment } from '#build/graphql-operations'

const props = defineProps<{
  overline?: string
  items: FieldListItemTyped[]
}>()

type Slide = {
  uuid: string
  props: ParagraphSwitcherItemFragment
}

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 400,
    md: 316,
    lg: 425,
    xl: 492,
    xxl: 598,
    xxxl: 1157,
  },
})

const slides = computed<Slide[]>(() =>
  props.items
    .filter((v) => v.bundle === 'switcher_item')
    .map<Slide>((v) => ({
      uuid: v.uuid,
      props: v.props,
    }))
    .filter((v) => v.props.image),
)

const activeIndex = ref(0)

const currentSlide = computed(() => slides.value[activeIndex.value])

const getContentStyle = (index: number) => {
  if (index === activeIndex.value) {
    return {
      transform: `translateX(0)`,
      opacity: 1,
    }
  } else if (index > activeIndex.value) {
    return {
      transform: `translateX(20px)`,
      opacity: 0,
    }
  }
  return {
    transform: `translateX(-60px)`,
    opacity: 0,
  }
}
</script>
